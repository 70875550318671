import { render, staticRenderFns } from "./EventPreviewImage.vue?vue&type=template&id=21285bc1&scoped=true"
import script from "./EventPreviewImage.vue?vue&type=script&lang=js"
export * from "./EventPreviewImage.vue?vue&type=script&lang=js"
import style0 from "./EventPreviewImage.vue?vue&type=style&index=0&id=21285bc1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21285bc1",
  null
  
)

export default component.exports