<template>
	<v-dialog :value="value" @input="$emit('input', $event)" max-width="500px" @keydown.esc="CloseDialog" persistent
		no-click-animation>
		<v-card>
			<v-card-text class="pt-3" v-if="itemDetails != null">
				<h3 class="pa-0">Numero Auto</h3>
				<v-text-field v-model="itemDetails.Name" label="Numero" :readonly="MainItemReadOnly"></v-text-field>

				<h3 class="mb-2">Testi Identificatori</h3>
				<div v-for="identifier in itemDetails.SgpEventItemIdentifiers" :key="identifier.IdentifierId">
					<v-text-field v-model="identifier.Text"></v-text-field>
				</div>
				<v-btn @click="addNewLine" text color="primary"><v-icon small>fas fa-plus</v-icon>Aggiungi
					identificatore</v-btn>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="CloseDialog" color="grey" text> {{ $t("common.cancel") }}</v-btn>
				<v-btn color="primary" @click="Update">{{ $t("common.save") }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	props: {
		value: { type: Boolean, required: true },
		EventItemId: { type: Number },
		MainItemReadOnly: { type: Boolean, default: false }
	},
	data() {
		return {
			itemDetails: null
		}
	},
	watch: {
		EventItemId: {
			immediate: true,
			handler: async function (val) {
				if (val) {
					await this.LoadItemDetails(val);
				}
			}
		}
	},
	created() {
		this.SgpEventsService = new CrudClient("SGP/Events");
		this.SgpEventItemsService = new CrudClient("Sgp/EventItems");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		CloseDialog() {
			this.$emit("input", false);
		},

		async LoadItemDetails(val) {
			const SgpEventItemsService = new CrudClient("Sgp/EventItems");
			this.itemDetails = await SgpEventItemsService.GetSelectedFields(val, "ItemId, Name, SgpEventItemIdentifiers.*");
		},

		addNewLine() {
			this.itemDetails.SgpEventItemIdentifiers.push({ IdentifierId: null, Text: "" });
		},

		async Update() {
			try {
				const updateData = {
					Name: this.itemDetails.Name
				};
				await this.SgpEventItemsService.Patch(this.EventItemId, updateData, false);

				// delete identifiers
				await this.SgpEventItemsService.Delete(this.EventItemId, "identifier")

				// add all one by one
				for (let i = 0; i < this.itemDetails.SgpEventItemIdentifiers.length; i++) {
					const currentItemText = this.itemDetails.SgpEventItemIdentifiers[i].Text;
					if (!currentItemText || currentItemText.length === 0) {
						continue;
					}

					var dto = { text: currentItemText };
					var identifierId = await this.SgpEventItemsService.PostCustom(null, dto, `${this.EventItemId}/identifier`, true);
					this.$log.debug("identifierId added: " + identifierId);
				}
				this.snackSuccess({ Text: this.$t("common.success.updateData") });
				await this.LoadItemDetails(this.EventItemId);
				this.$emit("dataUpdated", this.EventItemId);
				this.$emit("input", false);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			}
		}
	}
}
</script>
