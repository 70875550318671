<template>

		<!-- <img :src="file.Uri + SasToken" style="width: 100%; max-height: 800px; object-fit: contain;" /> -->
		<InnerImageZoom
			:src="file.Metadata.PreviewFile + SasToken"
			:zoomSrc="file.Uri + SasToken">
		</InnerImageZoom>
</template>
<script>
import { mapGetters } from "vuex";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
export default {
	components: {
		InnerImageZoom
	},
	props: {
		file: { type: Object, required: true },
	},
	watch: {
	},
	computed: {
		...mapGetters(["SasToken"]),
	},
	data() {
		return {
			// zoomed: false,
			// imageAspectRatio: 1,
		}
	},
	methods: {
		// onImageLoad(e) {
		// 	const img = e.target
		// 	this.imageAspectRatio = img.naturalWidth / img.naturalHeight
		// }
	},
}
</script>
