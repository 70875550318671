<template>
	<div v-if="Metadata">
		<!-- Manuel edit case -->
		<v-icon color="success" v-if="Metadata['manuelExif'] && Metadata['manuelExif'] === 'true'">fas
			fa-pen-square</v-icon>
		<template v-else>
			<!-- Not processed yet -->
			<v-icon color="warning lighten-1" v-if="Metadata.DetectedObjects === undefined">fas
				fa-question-circle</v-icon>
			<!-- No faces detected -->
			<v-icon color="deep-purple darken-1" v-if="parseInt(Metadata.DetectedObjects) === 0">fas
				fa-empty-set</v-icon>

			<!-- Faced found and identified -->
			<v-icon color="success" v-if="Metadata.DetectedObjects === Metadata.IdentifiedObjects &&
		Metadata.HasLowConfidenceResult !== 'true' &&
		parseInt(Metadata.IdentifiedObjects) > 0
		">fas fa-check-double</v-icon>

			<!-- Faced found and identified with problems -->
			<span v-if="Metadata.DetectedObjects !== Metadata.IdentifiedObjects ||
		Metadata.HasLowConfidenceResult === 'true'
		">
				<span class="infoText">{{ Metadata.DetectedObjects }} /
					{{ Metadata.IdentifiedObjects }}</span>

				<v-icon color="warning">fas fa-exclamation-triangle</v-icon>
			</span>
		</template>
	</div>
</template>
<script>
export default {
	data() {
		return {

		}
	},
	props: {
		Metadata: {
			type: Object,
		},
	}

}
</script>
<style lang="scss" scoped>
	.infoText {
		background-color: black;
		color: white;
		opacity: 0.5;
		padding: 2px 5px;
		border-radius: 4px;
		font-size: 16px;
	}
</style>
