<template>
	<!-- Persistent added to prevent close on snackbar click -->
	<v-dialog fullscreen persistent :value="value" @input="$emit('input', $event)" @keydown.esc="HideImageDialog"
		@keydown.left.exact="OnLeftKeyPress" @keydown.right.exact="OnRightKeyPress" :scrollable="false">
		<v-card>
			<v-toolbar dense float color="" v-if="carouselCurrentIndex >= 0">
				<v-btn icon @click="HideImageDialog">
					<v-icon>fas fa-arrow-left</v-icon>
				</v-btn>

				<v-toolbar-title>{{ currentFileName }}</v-toolbar-title>
			</v-toolbar>
			<v-container>
				<!---->
				<v-row>
					<v-col class="pa-2" cols="12" sm="7" lg="9">
						<v-carousel v-bind:value="carouselCurrentIndex" height="800" @change="OnCarouselCurrentIndex"
							:continuous="false" :hide-delimiters="true" prev-icon="fas fa-angle-left"
							next-icon="fas fa-angle-right">
							<v-carousel-item v-for="(file, i) in Files" :key="i">
								<div class="d-flex justify-center">
									<ZoomableImage v-if="file.Metadata && file.Metadata.PreviewFile" :file="file">
									</ZoomableImage>
									<template v-else>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<div class="text-center grey--text">
												<v-icon size="120" color="grey">fas fa-file-image </v-icon>
												<div class="ma-3 text-h5">
													{{ file.DisplayName }}
												</div>
											</div>
										</v-row>
									</template>
								</div>
							</v-carousel-item>
						</v-carousel>
					</v-col>
					<v-col cols="12" sm="5" lg="3" v-if="currentFile && currentFile.Metadata">
						<EditDetailsCard :currentFile="currentFile" :EventId="EventId" :key="currentFile.Uri"
							@exifUpdated="$emit('exifUpdated', $event)"
							@nextImage="$emit('carouselRight')">
						</EditDetailsCard>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-dialog>
</template>
<script>
import ZoomableImage from "./components/ZoomableImage";
import EditDetailsCard from "./components/EditDetailsCard";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "OcrImageDialog",
	components: {
		EditDetailsCard,
		ZoomableImage
	},
	props: {
		value: { type: Boolean, required: true },
		EventId: { type: Number, required: true },
		Files: { type: Array, required: true },
		carouselCurrentIndex: { type: Number },
		SgpEventsService: { type: Object, required: true },
	},
	data() {
		return {

		};
	},
	computed: {
		...mapGetters(["SasToken"]),

		currentFile() {
			return this.Files[this.carouselCurrentIndex];
		},

		currentFileName() {
			if (this.Files[this.carouselCurrentIndex]) {
				return this.Files[this.carouselCurrentIndex].Uri.split("/").pop();
			}
			return null;
		},
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);
		}
	},
	created() {
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		OnRightKeyPress() {
			// ;
		},
		OnLeftKeyPress() {
			// this.$emit("carouselLeft");
		},

		HideImageDialog() {
			this.$emit("input", false);
			this.$emit("scrollTo", this.currentFile);
		},

		OnCarouselCurrentIndex(param) {
			this.$emit("carousel-current-index-changed", param);
		},
		carouselResize() {
			this.$log.debug("carouselResize");
		},
	},
};
</script>
<i18n>
{
	"it":{
		"cannotRecognizeText": "Impossibile analizzare l'immagine"
	}
}
</i18n>
<style lang="scss" scoped>
.fullscreenContainer {
	height: calc(100vh - 48px) !important;
	/* max-height: calc(100vh-48px); */
}

.carouselImage {
	width: 100%;
	height: 100%;
	object-fit: contain;
	display: block;
}
</style>
