<template>
	<div>
		<confirm ref="confirm"></confirm>
		<vue-headful :title="$t('pageMetaDescription') " :description="$t('pageMetaDescription')" />
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
          <v-icon class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
      </template>
    </v-breadcrumbs>
		<EditItemDialog v-model="showEditItemDialog" :EventItemId="EventItemId"
			@dataUpdated="LoadEventItems">

		</EditItemDialog>

		<v-text-field v-model="search"
			label="Trova macchine"
			style="max-width: 400px;" clearable prepend-icon="fas fa-search">
		</v-text-field>
		<v-container>
			<v-row>
				<v-col cols="4" v-for="item in items" :key="item.ItemId">
					<v-card class="mb-4">
						<v-card-title>{{ item.Name }}</v-card-title>
						<v-card-text>
							<div v-for="identifier in item.SgpEventItemIdentifiers" :key="identifier.IdentifierId">
								<v-icon small class="mr-2">fas fa-tag</v-icon>
								{{ identifier.Text }}
							</div>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="OpenEditDialog(item.ItemId)" icon><v-icon>fas fa-edit</v-icon></v-btn>
							<v-btn @click="DeleteEventItem(item.ItemId)" icon>
								<v-icon>fas fa-trash</v-icon>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>

			<div class="text-center" v-if="itemsPaging">
				<v-pagination :disabled="LoadingData" v-model="currentPage" :length="itemsPaging.TotalPages"
					:total-visible="5"></v-pagination>
			</div>
		</v-container>
	</div>
</template>
<script>
import Confirm from "@/components/Shared/Common/Confirm";
import CrudClient from "@/services/CrudClient/";
import EditItemDialog from "./components/EditItemDialog";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "OcrEventDetails",
	components: {
		Confirm,
		EditItemDialog
	},
	data() {
		return {
			items: null,
			LoadingData: false,
			itemsPaging: null, // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 30,
			showEditItemDialog: false,
			EventItemId: null,
			search: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		}
	},
	computed: {
		...mapGetters(["SasToken"]),
		EventId() {
			return parseInt(this.$route.params.EventId);
		}
	},
	watch: {
		async currentPage() {
			await this.LoadEventItems();
		},
		async search() {
			await this.LoadEventItems();
		},
	},
	created() {
		this.SgpEventsService = new CrudClient("SGP/Events");
		this.SgpEventItemsService = new CrudClient("Sgp/EventItems");
	},
	async mounted() {
		this.eventDetails = await this.SgpEventsService.Get(this.EventId);

		this.breadcrumbsItems.push({
			text: this.$t("sgp.carEvents"),
			disabled: false,
			exact: true,
			to: "/1000MigliaEvents",
		});
		this.breadcrumbsItems.push({
			text: this.eventDetails.Name,
			disabled: false,
			exact: true,
			to: "/1000MigliaEvents/" + this.EventId,
		});

		this.breadcrumbsItems.push({
			text: "Elenco Macchine",
			disabled: true,
			exact: true,
			to: "/1000MigliaEvents/" + this.EventId + "/Cars",
		});

		await this.LoadEventItems();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		OpenEditDialog(eventItemId) {
			this.showEditItemDialog = true;
			this.EventItemId = eventItemId;
		},

		async DeleteEventItem(eventItemId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}
			try {
				await this.SgpEventItemsService.Delete(eventItemId);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteData") });
			}

			await this.LoadEventItems();
		},

		async LoadEventItems() {
			try {
				this.LoadingData = true;

				const res = await this.SgpEventItemsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					urlPostFix: "Event/" + this.EventId,
					filter: this.search ? `Name:sw:${this.search}` : "",
					fields: "*, SgpEventItemIdentifiers.Text",
					orderBy: "Name",
				});

				this.itemsPaging = res.Paging;
				this.items = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},
	}
}
</script>
<i18n>
	{
		"it": {
			"pageMetaDescription": "Elenco macchine"
		}
	}
</i18n>
