<template>
	<v-img :src="file.Metadata.PreviewFile + SasToken" :alt="file.DisplayName" :aspect-ratio="file.Metadata.AspectRatio"
		class="grey darken-4 rounded" @click="$emit('click')">
		<!-- <div class="ArtistInfo" v-if="showArtist && file.Metadata && file.Metadata.Artist">
			{{ file.Metadata.Artist }}
		</div> -->

		<div class="ImageDescription" v-if="
				showImageDescription && file.Metadata && file.Metadata.ImageDescription
			">
			{{ file.Metadata.ImageDescription }}
		</div>
		<v-icon v-if="file.Metadata.TagColor" class="ColorTag" :style="{ backgroundColor: tagColor }" x-small dark>
			fas fa-star
		</v-icon>
		<DetectionSummaryLabel class="FaceRecognitionIcon" v-if="showImageDescription && file.Metadata"
			:Metadata="file.Metadata">
		</DetectionSummaryLabel>
		<template v-slot:placeholder>
			<v-row class="fill-height ma-0" align="center" justify="center">
				<v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
			</v-row>
		</template>
	</v-img>
</template>
<style lang="scss" scoped>
	.ArtistInfo {
		position: absolute;
		bottom: 5px;
		right: 5px;
		background-color: black;
		opacity: 0.5;
		padding: 2px 5px;
		border-radius: 4px;
		color: white;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 90%;
	}

	.ImageDescription {
		position: absolute;
		bottom: 5px;
		left: 5px;
		background-color: black;
		opacity: 0.5;
		padding: 2px 5px;
		border-radius: 4px;
		color: white;
		// white-space: nowrap;
		// overflow: hidden;
		// text-overflow: ellipsis;
		max-width: 90%;
	}

	.ColorTag {
		position: absolute;
		top: 10px;
		right: 45px;
		height: 26px;
		width: 26px;
		border-radius: 50%;
	}

	.FaceRecognitionIcon {
		position: absolute;
		top: 5px;
		right: 5px;

		max-width: 90%;
		background-color: black;
		padding: 5px 5px;
		border-radius: 4px;
		opacity: 0.9;
	}
</style>
<script>
import { mapGetters } from "vuex";
import DetectionSummaryLabel from "./DetectionSummaryLabel";

export default {
	components: {
		DetectionSummaryLabel
	},
	computed: {
		...mapGetters(["SasToken"]),

		tagColor() {
			const colors = [{
				hex: "#FE00FE",
				id: 1
			},
			{
				hex: "#FA0202",
				id: 2
			},
			{
				hex: "#FD8002",
				id: 3
			},
			{
				hex: "#FFFB00",
				id: 4
			},
			{
				hex: "#00FF02",
				id: 5
			},
			{
				hex: "#00FFFD",
				id: 6
			},
			{
				hex: "#0100FA",
				id: 7
			},
			{
				hex: "#646464",
				id: 8
			}
			];
			// this.$log.debug(this.file?.Tags?.TagColor);
			// this.$log.debug(colors.find(c => c.id.toString() === this.file?.Tags?.TagColor)?.hex);
			return colors.find(c => c.id.toString() === this.file.Metadata?.TagColor)?.hex;
		}
	},

	props: {
		file: {
			type: Object,
		},
		showArtist: {
			type: Boolean,
			default: false,
		},
		showImageDescription: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
	}
};
</script>
