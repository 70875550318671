<template>
	<v-card class="my-4">
		<v-card-text>
			<div class="text-subtitle-2 font-weight-bold mb-2">Aggiungi dettagli per migliorare il
				database</div>
				<!-- <h3 class="text-h6">{{ RecognizedCar.TagName }}</h3> -->
				<div v-if="RecognizedCar.MatchingResult">
					<div v-for="(line, index) in RecognizedCar.Lines" :key="index">
						{{ line.Text }}
						<v-icon v-if="line.MatchingItems.length === 0"
							@click="addIdentifierToItem(RecognizedCar.MatchingResult.SgpEventItemId, line.Text)">fas
							fa-plus-circle</v-icon>
						<v-icon v-else small color="success">fas fa-check</v-icon>
					</div>
				</div>
				<div v-if="!RecognizedCar.MatchingResult">
					<div v-for="(line, index) in RecognizedCar.Lines" :key="index">
						<v-checkbox hide-details="" v-model="line.selected" :label="line.Text"></v-checkbox>
					</div>
					<v-radio-group v-model="isNewCar" row>
						<v-radio :value="true" label="Nuova macchina"></v-radio>
						<v-radio :value="false" label="Macchina esistente"></v-radio>
					</v-radio-group>
					<template v-if="isNewCar">
						<v-text-field label="Numero Macchina" v-model="FormNewNumber"></v-text-field>
						<v-btn color="primary" block :disabled="!FormNewNumber" @click=addItemWithSelectedText()>{{
							$t("common.add") }} ad
							Evento</v-btn>
					</template>
					<template v-else>
						<EventItemPicker v-model="selectedAddToItemId" label="N° Auto" :EventId="EventId" filled>
						</EventItemPicker>
						<v-btn color="primary" block :disabled="!selectedAddToItemId" @click=addToExistingItem()>
							Aggiungi Identificatore
						</v-btn>
					</template>
				</div>

				<div v-if="RecognizedCar.MatchingResult">
					<EventItemLabel :itemId="RecognizedCar.MatchingResult.SgpEventItemId"
						:confidence="RecognizedCar.MatchingResult.Confidence">
					</EventItemLabel>
					<v-btn icon @click="showEditItemDialog = true"><v-icon>fas fa-edit</v-icon></v-btn>
					<EditItemDialog v-model="showEditItemDialog"
						:EventItemId="RecognizedCar.MatchingResult.SgpEventItemId" :MainItemReadOnly="true">
					</EditItemDialog>
					<div class="mt-2">
						<v-btn color="warning darken-1" small @click="RecognizedCar.MatchingResult = null">
							<v-icon left color="white">fas fa-retweet-alt</v-icon>
							cambia macchina</v-btn>
					</div>

				</div>

		</v-card-text>
	</v-card>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import EventItemLabel from "./1000MigliaItemLabel"
import EventItemPicker from "./EventItemPicker"
import { mapActions, mapGetters } from "vuex";
import EditItemDialog from "./EditItemDialog";

export default {
	name: "AddEditCarCard",
	components: {
		EventItemLabel,
		EventItemPicker,
		EditItemDialog
	},
	props: {
		EventId: { type: Number, required: true },
		RecognizedCar: { type: Object, required: true },
	},
	data() {
		return {
			isNewCar: false,
			selectedAddToItemId: null,
			FormNewNumber: null,
			showEditItemDialog: false
		}
	},
	computed: {
		...mapGetters(["SasToken"]),
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async addIdentifierToItem(SgpEventItemId, text) {
			const eventItemsService = new CrudClient("Sgp/EventItems");
			try {
				var dto = { text: text };
				var identifierId = await eventItemsService.PostCustom(null, dto, `${SgpEventItemId}/identifier`, true);
				this.$log.debug("identifierId added: " + identifierId);
				this.$emit("EventItemUpdated");
				this.snackSuccess({ Text: "Identifier Aggiunto" });
			} catch (error) {
				this.snackError({ Text: "cannotAddItem" });
				this.$captureError(error);
			} finally {
			}
		},

		async addToExistingItem() {
			const selectedLines = this.RecognizedCar.Lines.filter(l => l.selected);
			this.$log.debug(selectedLines);

			const eventItemsService = new CrudClient("Sgp/EventItems");
			try {
				for (let i = 0; i < selectedLines.length; i++) {
					const dto = { text: selectedLines[i].Text };
					const identifierId = await eventItemsService.PostCustom(null, dto, `${this.selectedAddToItemId}/identifier`, true);
					this.$log.debug("identifierId added: " + identifierId);
				}
				this.$emit("EventItemUpdated");
				this.snackSuccess({ Text: "Articolo Aggiunto" });
			} catch (error) {
				this.snackError({ Text: "cannotAddItem" });
				this.$captureError(error);
			} finally {
			}
		},

		async addItemWithSelectedText() {
			const selectedLines = this.RecognizedCar.Lines.filter(l => l.selected);
			this.$log.debug(selectedLines);

			const eventItemsService = new CrudClient("Sgp/EventItems");
			try {
				var dto = {
					EventId: this.EventId,
					Name: this.FormNewNumber
				};
				var itemId = await eventItemsService.PostCustom(null, dto, null, true);
				this.$log.debug("Event Item added: " + itemId);

				dto = { text: this.FormNewNumber };
				var identifierId = await eventItemsService.PostCustom(null, dto, `${itemId}/identifier`, true);
				this.$log.debug("identifierId added: " + identifierId);

				for (let i = 0; i < selectedLines.length; i++) {
					dto = { text: selectedLines[i].Text };
					identifierId = await eventItemsService.PostCustom(null, dto, `${itemId}/identifier`, true);
					this.$log.debug("identifierId added: " + identifierId);
				}
				this.$emit("EventItemUpdated");
				this.snackSuccess({ Text: "Articolo Aggiunto" });
			} catch (error) {
				this.snackError({ Text: "cannotAddItem" });
				this.$captureError(error);
			} finally {
			}
		},

	}
}
</script>
