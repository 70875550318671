<template>
	<v-card class="mb-3">
		<v-card-title>Auto Rilevate
			<v-spacer></v-spacer>

			<DetectionSummaryLabel :Metadata="currentFile.Metadata" class="SummaryBlock">
			</DetectionSummaryLabel>
		</v-card-title>

		<v-card-text>
			<ColorTagPicker v-model="colorTag" @selected="selectTag" @delete="deleteTag"></ColorTagPicker>
			<!-- {{ InitialTaggedItemIds }} /
			{{ TaggedItemIds }} -->
			<div class="mt-2">
				<v-alert v-if="TaggedItemIds.length === 0" color="grey lighten-2" icon="fas fa-tag">
					Non ci sono macchine taggate
				</v-alert>
				<EventItemLabel class="mr-2" close v-for="itemId in TaggedItemIds" @click:close="removeItem(itemId)"
					:key="itemId" :itemId="itemId" :confidence="1">
				</EventItemLabel>
				<v-container>
					<v-row dense>
						<v-col>
							<EventItemPicker label="N° Auto" v-model="selectedItemId" :EventId="EventId" filled>
							</EventItemPicker>
						</v-col>
						<v-col>
							<v-btn color="primary" large block outlined @click="AddTaggedItem(selectedItemId)"
								:disabled="!selectedItemId">
								<v-icon left>fas fa-plus</v-icon>
								{{ $t("common.add") }}
							</v-btn>
						</v-col>
					</v-row>
					<v-row dense>
						<v-btn large block color="primary" v-if="TaggedItemsModified" @click="UpdateTaggedEventItemIds"
							:loading="updatingTaggedItems">Aggiorno i tag</v-btn>
						<v-btn @click="MarkAsOk" large block :loading="updatingOkResult" v-else-if="IsMarkAsOkVisibile"
							color="success">Conferma</v-btn>
						<v-btn large block color="primary" v-else :disabled="true">Aggiorno i tag</v-btn>
					</v-row>
				</v-container>
			</div>

		</v-card-text>
		<v-divider class="ma-3"></v-divider>
		<v-card-title>Dettagli dell'auto</v-card-title>
		<v-card-text>
			<v-alert color="grey lighten-2"
				v-if="detectionResult && detectionResult.filter(c => c.Lines.length > 0).length === 0"
				icon="far fa-comment-alt">
				Nessun testo trovato
			</v-alert>
			<div v-if="detectionResult">
				<AddEditCarCard v-for="(car, index) in detectionResult.filter(c => c.Lines.length > 0)" :key="index"
					@EventItemUpdated="RecognizeText" :EventId="EventId" :RecognizedCar="car">
				</AddEditCarCard>
			</div>
			<v-btn color="primary" block :loading="OCRLoading" @click="RecognizeText">
				<v-icon left>fas fa-draw-square</v-icon> RILEVA TESTI/RESET</v-btn>
		</v-card-text>
	</v-card>
</template>
<script>
import { mapActions } from "vuex";
import api from "@/services/api"
import CrudClient from "@/services/CrudClient/";

import EventItemPicker from "./EventItemPicker"
import AddEditCarCard from "./AddEditCarCard"
import DetectionSummaryLabel from "./DetectionSummaryLabel";
import EventItemLabel from "./1000MigliaItemLabel"
import ColorTagPicker from "./ColorTagPicker";

export default {
	components: {
		EventItemLabel,
		EventItemPicker,
		AddEditCarCard,
		DetectionSummaryLabel,
		ColorTagPicker
	},
	props: {
		EventId: { type: Number, required: true },
		currentFile: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			OCRLoading: false,
			updatingTaggedItems: false,
			detectionResult: null,
			selectedItemId: null,

			InitialTaggedItemIds: [],
			TaggedItemIds: [],
			updatingOkResult: false,
			colorTag: null
		};
	},
	computed: {
		TaggedItemsModified() {
			return !this.arraysAreEqual(this.TaggedItemIds, this.InitialTaggedItemIds);
		},

		IsManualTagged() {
			const Metadata = this.currentFile.Metadata;

			return (Metadata.manuelExif && Metadata.manuelExif === "true");
		},

		IsMarkAsOkVisibile() {
			const Metadata = this.currentFile.Metadata;

			if (this.IsManualTagged) {
				return false;
			}

			if (
				Metadata.DetectedObjects === Metadata.IdentifiedObjects &&
				Metadata.HasLowConfidenceResult !== "true" &&
				parseInt(Metadata.IdentifiedObjects) > 0) {
				return false;
			}

			return true;
		}
	},

	watch: {
		// colorTag: {
		// 	handler: async function (val) {
		// 		const azureFunctionClient = new CrudClient("Ocr", api.azureFunctionApiUrl);
		// 		// Ocr/{applicationId}/{eventId}/TagColor/{tagColor}

		// 		const dto = {
		// 			ImageFilePath: this.currentFile.Path
		// 		};

		// 		let updatedItem;
		// 		if (this.colorTag) {
		// 			updatedItem = await azureFunctionClient.Post(
		// 				this.EventId, dto, `TagColor/${val}`, true);
		// 		} else {
		// 			updatedItem = await azureFunctionClient.Delete(
		// 				this.EventId, "TagColor", true, true, dto);
		// 		}

		// 		this.$emit("exifUpdated", updatedItem);
		// 		// this.$log.debug(updatedItem);
		// 	}
		// },
		currentFile: {
			immediate: true,
			handler: async function (val) {
				this.selectedItemId = null;

				if (this.currentFile.Metadata.TextAnalysisResultsFileId) {
					await this.LoadAnalysisResults(this.currentFile.Metadata.TextAnalysisResultsFileId);
				}

				this.colorTag = parseInt(this.currentFile.Metadata.TagColor);
			},
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async selectTag(tagId) {
			const azureFunctionClient = new CrudClient("Ocr", api.azureFunctionApiUrl);
			// Ocr/{applicationId}/{eventId}/TagColor/{tagColor}

			const dto = {
				ImageFilePath: this.currentFile.Path
			};

			const updatedItem = await azureFunctionClient.Post(
				this.EventId, dto, `TagColor/${tagId}`, true);

			this.$emit("exifUpdated", updatedItem);
			// this.$log.debug(updatedItem);
		},

		async deleteTag() {
			const azureFunctionClient = new CrudClient("Ocr", api.azureFunctionApiUrl);
			const dto = {
				ImageFilePath: this.currentFile.Path
			};

			const updatedItem = await azureFunctionClient.Delete(
				this.EventId, "TagColor", true, true, dto);

			this.$emit("exifUpdated", updatedItem);
			// this.$log.debug(updatedItem);
		},

		initTaggedItemIdsFromMetadata() {
			this.TaggedItemIds.splice(0);
			this.InitialTaggedItemIds.splice(0);

			this.stringToItemIdArray(this.currentFile.Metadata.EventItemIds).forEach(element => {
				this.TaggedItemIds.push(element)
			});

			this.InitialTaggedItemIds = this.TaggedItemIds.slice();
		},

		async RecognizeText() {
			this.OCRLoading = true;
			try {
				const azureFunctionClient = new CrudClient("Ocr", api.azureFunctionApiUrl);

				var analysisResult = await azureFunctionClient.Post(
					this.EventId,
					{ ImageFilePath: this.currentFile.Path },
					"AnalyseImage?useCachedIdentifiers=false",
					true
				);

				analysisResult.ObjectRecognitionResults.map(o => {
					o.FormNewNumber = null;
					o.Lines.map(l => { l.selected = false; return l; });
					return o;
				});

				this.detectionResult = analysisResult.ObjectRecognitionResults;

				this.$emit("exifUpdated", analysisResult.BlobFileDTO);

				this.initTaggedItemIdsFromMetadata();
			} catch (error) {
				this.snackError({ Text: "cannotRecognizeText" });
				this.$captureError(error);
			} finally {
				this.OCRLoading = false;
			}
		},

		async LoadAnalysisResults(fileId) {
			const azureFunctionClient = new CrudClient("Ocr", api.azureFunctionApiUrl);
			var ObjectRecognitionResults = await azureFunctionClient.Get(
				"AnalysisResult/" + fileId,
			);

			ObjectRecognitionResults.map(o => {
				o.Lines.map(l => { l.selected = false; return l; });
				return o;
			});

			this.detectionResult = ObjectRecognitionResults;

			this.initTaggedItemIdsFromMetadata();
		},

		async MarkAsOk() {
			try {
				this.updatingOkResult = true;

				const dto = {
					ImageFilePath: this.currentFile.Path
				};

				const azureFunctionClient = new CrudClient("Ocr", api.azureFunctionApiUrl);
				// "Ocr/{applicationId}/{eventId}/MarkAsOk

				var updatedItem = await azureFunctionClient.Post(
					this.EventId, dto, "MarkAsOk", true);

				this.initTaggedItemIdsFromMetadata();
				this.$emit("exifUpdated", updatedItem);
				this.$emit("nextImage");

				this.snackSuccess({ Text: "Metadata aggiornato" });
			} catch (error) {
				this.snackError("cannotUpdateData");
				this.$captureError(error);
			} finally {
				this.updatingOkResult = false;
			}
		},
		AddTaggedItem(selectedItemId) {
			if (!this.TaggedItemIds.includes(selectedItemId)) {
				this.TaggedItemIds.push(selectedItemId);
			}
		},

		removeItem(itemId) {
			this.TaggedItemIds = this.TaggedItemIds.filter(f => f !== itemId);
		},

		async UpdateTaggedEventItemIds() {
			try {
				this.updatingTaggedItems = true;

				const dto = {
					ImageFilePath: this.currentFile.Path,
					ItemIds: this.TaggedItemIds.join(",")
				};

				const azureFunctionClient = new CrudClient("Ocr", api.azureFunctionApiUrl);
				var res = await azureFunctionClient.Post(
					this.EventId,
					dto,
					"EventItems",
					true
				);
				this.$emit("exifUpdated", res);
				this.initTaggedItemIdsFromMetadata();
				this.snackSuccess({ Text: "Metadata aggiornato" });
			} catch (error) {
				this.snackError("cannotUpdateData");
				this.$captureError(error);
			} finally {
				this.updatingTaggedItems = false;
			}
		},

		stringToItemIdArray(str) {
			if (!str) { return []; }
			var arr = str.split(", ").filter(i => i).map(Number);
			return arr;
		},

		arraysAreEqual(arr1, arr2) {
			if (arr1.length !== arr2.length) {
				return false;
			}
			for (let i = 0; i < arr1.length; i++) {
				if (arr1[i] !== arr2[i]) {
					return false;
				}
			}
			return true;
		},

	}
}
</script>
