<template>
	<span>
		<v-progress-linear indeterminate v-if="Loading"></v-progress-linear>
		<v-chip v-else :color="labelColor" v-bind="$attrs" v-on="$listeners" dark label>
			{{ this.Name }}
		</v-chip>
	</span>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	props: {
		itemId: { type: Number, default: null },
		confidence: { type: Number },
	},
	data() {
		return { Name: null, Loading: false };
	},
	computed: {
		labelColor() {
			if (this.confidence > 0.95) {
				return "green darken-4";
			}

			if (this.confidence > 0.9) {
				return "green darken-3";
			}

			if (this.confidence > 0.85) {
				return "green darken-2";
			}

			if (this.confidence > 0.8) {
				return "green darken-1";
			}

			if (this.confidence > 0.75) {
				return "green lighten-1";
			}
			if (this.confidence > 0.7) {
				return "green lighten-2";
			}

			return "grey darken-2";
		},
	},
	watch: {
		itemId: {
			immediate: true,
			handler: async function (newVal) {
				if (newVal) {
					await this.GetEventItem();
				} else {
					this.Name = null;
				}
			},
		},
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async GetEventItem() {
			try {
				this.Loading = true;

				const EventItemsService = new CrudClient("Sgp/EventItems");
				const res = await EventItemsService.Get(this.itemId);

				if (res) this.Name = res.Name;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.Loading = false;
			}
		},
	},
};
</script>
