<template>
	<div>
		<vue-headful
			:title="$t('sgp.1000Miglia')"
			:description="$t('pageMetaDescription')"
		/>
		<confirm ref="confirm"></confirm>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
      </template>
		</v-breadcrumbs>

		<v-card class="elevate-5">
			<v-toolbar flat class="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("sgp.1000Miglia") }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<SgpEventPicker
					style="max-width: 400px"
					prepend-icon="fas fa-search"
					label="Cerca evento"
					v-model="selectedEventId"
					@change="$router.push('/1000Miglia/' + selectedEventId)"
					:EventType="2"
				></SgpEventPicker>
			</v-toolbar>
			<div style="height: 0px; position: relative">
				<v-btn
					absolute
					dark
					fab
					top
					right
					color="green"
					@click="OpenNewEventDialog"
				>
					<v-icon>add</v-icon>
				</v-btn>
			</div>

			<v-progress-linear indeterminate v-if="LoadingData"></v-progress-linear>
			<div ref="offerPagingStart"></div>

			<v-row dense class="pa-4">
				<v-col
					cols="12"
					sm="6"
					lg="3"
					class="mb-4 static-navigation"
					v-for="item in items"
					:key="item.EventId"
				>
					<v-card
						class="pa-1 elevateOnHover"
						:to="{ path: '/1000MigliaEvents/' + item.EventId }"
						append
					>
						<v-card-title class="px-2"> {{ item.Name }}</v-card-title>
						<v-card-text>
							<div class="mb-3 text--primary">
								<span class="font-weight-bold pr-1">{{ $t("location") }}:</span
								>{{ item.Location }}

								<br />

								<span class="font-weight-bold pr-1">{{ $t("eventDate") }}:</span
								>{{ item.EventDate | formatDate }}
							</div>

							{{ $t("common.createDate") }}:
							{{ item.CreateDate | formatLocalDateTime }}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn icon @click="DeleteEvent(item.EventId, $event)">
								<v-icon>fas fa-trash</v-icon>
							</v-btn>
							<v-btn icon @click="ShowEditEventDialog(item.EventId, $event)">
								<v-icon>fas fa-edit</v-icon>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>

			<div class="text-center" v-if="itemsPaging">
				<v-pagination
					:disabled="LoadingData"
					v-model="currentPage"
					:length="itemsPaging.TotalPages"
					:total-visible="5"
				></v-pagination>
			</div>
		</v-card>
		<AddEditEventDialog
			v-model="showAddEditEventDialog"
			:mode="dialogMode"
			:EventId="EventId"
			@updated="LoadEvents"
			:EventType="3"
		></AddEditEventDialog>
	</div>
</template>

<script>
import CrudClient from "@/services/CrudClient/";
import AddEditEventDialog from "@/components/Event/AddEditEventDialog";
import Confirm from "@/components/Shared/Common/Confirm";
import SgpEventPicker from "@/components/Event/SgpEventPicker";

import { mapActions } from "vuex";
export default {
	components: { AddEditEventDialog, Confirm, SgpEventPicker },
	data() {
		return {
			items: null,
			LoadingData: false,
			itemsPaging: null, // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 24,
			showAddEditEventDialog: false,
			dialogMode: 1,
			EventId: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			selectedEventId: null,
		};
	},
	watch: {
		// when dialog is closed reset id so we can reload values when re-open
		showAddEditEventDialog: async function (val) {
			this.$log.debug("showDetshowAddEditEventDialogailsDialog:" + val);
			if (!val) {
				this.EventId = null;
			}
		},

		async currentPage() {
			await this.LoadEvents();
			this.scrollToElement("offerPagingStart");
		},
	},

	created() {
		this.SgpEventsService = new CrudClient("SGP/Events");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("sgp.1000Miglia"),
			disabled: true,
			exact: true,
			to: "/1000MigliaEvents",
		});

		await this.LoadEvents();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadEvents() {
			try {
				this.LoadingData = true;

				const res = await this.SgpEventsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					filter: "EventType:3",
					fields: "*",
					orderBy: "EventId:desc",
				});

				this.itemsPaging = res.Paging;
				this.items = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},

		OpenNewEventDialog() {
			this.showAddEditEventDialog = true;
			this.dialogMode = 1;
			this.EventId = null;
		},

		ShowEditEventDialog(eventId, event) {
			event.preventDefault();
			this.showAddEditEventDialog = true;
			this.dialogMode = 2;
			this.EventId = eventId;
		},

		async DeleteEvent(eventId, event) {
			event.preventDefault();

			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}

			try {
				this.LoadingData = true;

				await this.SgpEventsService.Delete(eventId);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
			await this.LoadEvents();
		},

		scrollToElement(element, container, enableSmooth = true) {
			if (this.$refs[element]) {
				(container || window).scrollTo({
					top: this.$refs[element].offsetTop,
					left: 0,
					behavior: enableSmooth ? "smooth" : undefined,
				});
			}
		},
	},
};
</script>

<i18n>
{
    "it": {
		"location": "Location",
		"eventDate": "Data evento"
    }
}
</i18n>
