<template>
	<v-menu offset-y right>
		<template v-slot:activator="{ on, attrs }">
			<v-btn :color="backgroundColor" dark v-bind="attrs" v-on="on">
				<v-icon left>fas fa-star</v-icon> {{ value ? "" : "Seleziona Tag" }}
			</v-btn>
		</template>
		<v-container fluid style="background-color: white;">
			<v-row dense>
				<v-col cols="2" v-for="(item, index) in colors" :key="index"
					@click="$emit('input', item.id); $emit('selected', item.id)">
					<div :style="{ 'background-color': item.hex }"
						style="border-radius: 50%; height:30px; width: 30px;">

					</div>
				</v-col>
				<v-col cols="2" v-if="showDelete">
					<v-btn icon @click="$emit('input', null); $emit('delete')">
						<v-icon color="grey darken-2">fas fa-trash</v-icon>
					</v-btn>

				</v-col>
			</v-row>

		</v-container>
	</v-menu>
</template>
<script>
export default {
	props: {
		value: { type: Number, default: null },
		showDelete: { type: Boolean, default: true }
	},
	computed: {
		backgroundColor() {
			if (!this.value) {
				return "primary";
			}

			this.$log.debug(this.value);

			return this.colors.find(c => c.id === this.value).hex;
		}
	},
	data() {
		return {
			colors: [{
				hex: "#FE00FE",
				id: 1
			},
			{
				hex: "#FA0202",
				id: 2
			},
			{
				hex: "#FD8002",
				id: 3
			},
			{
				hex: "#FFFB00",
				id: 4
			},
			{
				hex: "#00FF02",
				id: 5
			},
			{
				hex: "#00FFFD",
				id: 6
			},
			{
				hex: "#0100FA",
				id: 7
			},
			{
				hex: "#646464",
				id: 8
			}
			]
		}
	}
}
</script>
