<template>
	<v-autocomplete
		v-bind="$attrs"
		v-bind:value="value"
		v-on="$listeners"
		@input="$emit('input', $event)"
		:loading="LoadingItems"
		:label="$attrs.label || $t('Numero Macchina')"
		:items="eventItems"
		item-text="Name"
		item-value="ItemId"
		:search-input.sync="search"
		clearable
	></v-autocomplete>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			LoadingItems: false,
			eventItems: [],
			search: null,
		};
	},
	props: {
		value: { type: Number, default: null },
		EventId: { type: Number, required: true }
	},
	watch: {
		async search(val) {
			this.$log.debug("Watch search: " + val);
			val &&
				val !== this.value &&
				val.length > 0 &&
				(await this.LoadEventItems(val));
		},

		value: {
			immediate: true,
			handler: async function (newVal) {
				if (!newVal) return;
				// Programmatic value set
				if (!this.eventItems.find((i) => i.ItemId === newVal)) {
					var eventItemsService = new CrudClient("Sgp/EventItems");
					const res = await eventItemsService.GetSelectedFields(
						newVal,
						"ItemId, Name"
					);

					this.search = res.Name;
				}
			},
		},
	},
	created() {
		this.EventItemsService = new CrudClient("Sgp/EventItems");
	},
	async mounted() {
		await this.LoadEventItems();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadEventItems(val) {
			try {
				this.LoadingItems = true;
				const res = await this.EventItemsService.GetPaged({
					urlPostFix: `Event/${this.EventId}`,
					limit: 20,
					skip: 0,
					fields: "ItemId, Name",
					filter: `Name:sw:${val}`,
					orderBy: "Name",
				});
				this.eventItems = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingItems = false;
			}
		},
	},
};
</script>
